import axios from 'axios';
import { message } from 'ant-design-vue';
import store from '../store';
import { getToken } from '@/utils/auth';
let baseUrl = window.location.origin + '/api';
// 创建axios实例
const instance = axios.create({
  baseURL: baseUrl, // api的base_url
});

// request拦截器
instance.interceptors.request.use(
  (config: any) => {
    config.headers.Source = 'admin'; //后台
    config.headers.TimeZone = `${0 - new Date().getTimezoneOffset() / 60}`;
    if (config.method == 'post' && !config.data) {
      config.data = {};
    }
    if (store.getters.token) {
      config.headers['Authorization'] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config;
  },
  (error: any) => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// respone拦截器
instance.interceptors.response.use(
  function (response: any) {
    const res = response.data;

    if (response.status === 200 && res.size) {
      return response.data;
    }

    if (res.code !== 200) {
      // 401:未登录;
      if (res.code === 401) {
        message.error(res.message);
        store.dispatch('FedLogOut').then(() => {
          location.reload(); // 为了重新实例化vue-router对象 避免bug
        });
      } else {
        message.error(res.message, 3);
      }
      return Promise.reject('error');
    } else {
      return response.data;
    }
  },
  function (error: any) {
    console.log('err' + error); // for debug
    message.error(error.message, 3);
    return Promise.reject(error);
  }
);
export default instance;

import request from '@/utils/request';
import CryptoJS from 'crypto-js';
export function login(username: any, password: any) {
  password = CryptoJS.MD5(password).toString();
  return request({
    url: '/card-admin/admin/manager/login',
    method: 'post',
    data: {
      username,
      password,
    },
  });
}

export function getInfo() {
  return request({
    url: '/card-admin/admin/manager/info',
    method: 'post',
  });
}

export function logout() {
  return request({
    url: '/card-admin/admin/manager/logout',
    method: 'post',
  });
}

export function fetchList(params: any) {
  return request({
    url: '/card-admin/admin/manager/list',
    method: 'post',
    data: params,
  });
}

export function createAdmin(data: any) {
  // data.password = CryptoJS.MD5(data.password).toString();
  return request({
    url: '/card-admin/admin/manager/register',
    method: 'post',
    data: data,
  });
}

export function updateAdmin(id: any, data: any) {
  data.password = CryptoJS.MD5(data.password).toString();
  return request({
    url: '/card-admin/admin/manager/update',
    method: 'post',
    data: data,
  });
}

export function updateStatus(params: any) {
  return request({
    url: '/card-admin/admin/manager/updateStatus',
    method: 'post',
    data: params,
  });
}

export function deleteAdmin(params: any) {
  return request({
    url: '/card-admin/admin/manager/delete',
    method: 'post',
    data: params,
  });
}

export function getRoleByAdmin(params: any) {
  return request({
    url: '/card-admin/admin/manager/role',
    method: 'post',
    data: params,
  });
}

export function allocRole(data: any) {
  return request({
    url: '/card-admin/admin/manager/role/update',
    method: 'post',
    data: data,
  });
}

import { login, logout, getInfo } from '@/api/login';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { fetchRate } from '@/api/data';

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    exchangeAmount: 100,
  },

  mutations: {
    SET_TOKEN: (state: any, token: any) => {
      state.token = token;
    },
    SET_NAME: (state: any, name: any) => {
      state.name = name;
    },
    SET_AVATAR: (state: any, avatar: any) => {
      state.avatar = avatar;
    },
    SET_ROLES: (state: any, roles: any) => {
      state.roles = roles;
    },
    SET_RATE: (state: any, exchangeAmount: any) => {
      state.exchangeAmount = exchangeAmount;
    },
  },

  actions: {
    // 登录
    Login({ commit }: any, userInfo: any) {
      const username = userInfo.username.trim();
      return new Promise<void>((resolve, reject) => {
        login(username, userInfo.password)
          .then((response: any) => {
            const data = response.data;
            const tokenStr = data.tokenHead + data.token;
            setToken(tokenStr);
            commit('SET_TOKEN', tokenStr);
            resolve();
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },

    // 获取用户信息
    GetInfo({ commit, state }: any) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((response: any) => {
            const data = response.data;
            console.log(data, '123123');
            if (data.roles && data.roles.length > 0) {
              // 验证返回的roles是否是一个非空数组
              commit('SET_ROLES', data.roles);
            } else {
              reject('getInfo: roles must be a non-null array !');
            }
            commit('SET_NAME', data.nickname);
            commit('SET_AVATAR', data.photo);
            resolve(response);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },

    // 登出
    LogOut({ commit, state }: any) {
      return new Promise<void>((resolve, reject) => {
        logout()
          .then(() => {
            commit('SET_TOKEN', '');
            commit('SET_ROLES', []);
            removeToken();
            resolve();
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },

    // 前端 登出
    FedLogOut({ commit }: any) {
      return new Promise<void>((resolve) => {
        commit('SET_TOKEN', '');
        removeToken();
        resolve();
      });
    },
    //存储汇率 暂时没用
    GetSystemRate({ commit }: any) {
      return new Promise((resolve, reject) => {
        fetchRate()
          .then((response: any) => {
            commit('SET_RATE', response.data.exchangeAmount);
            resolve(response.data.exchangeAmount);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },
  },
};

export default user;

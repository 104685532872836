import router from "./router";
import store from "./store";
import { getToken } from "./utils/auth"; // 验权
import { message } from "ant-design-vue";

const whiteList = ["/login"]; // 不重定向白名单
router.beforeEach((to, from, next) => {
  // next();
  if (getToken()) {
    if (to.path === "/login") {
      next({ path: "/" });
    } else {
      if (store.getters.roles.length === 0) {
        store
          .dispatch("GetInfo")
          .then((res) => {
            // 拉取用户信息
            const menus: any = res.data.menus;
            const username: any = res.data.username;
            store.dispatch("GenerateRoutes", { menus, username }).then(() => {
              // 生成可访问的路由表
              router.addRoute(store.getters.addRouters); // 动态添加可访问路由表
              next({ ...to, replace: true });
            });
            // store.dispatch("GetSystemRate").then((red) => {
            //   localStorage.setItem("rate", red);
            // });
          })
          .catch((err) => {
            // next({ path: '/' })
            store.dispatch("FedLogOut").then(() => {
              message.error(err || "Verification failed, please login again");
              next({ path: "/login" });
            });
          });
      } else {
        next();
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next("/login");
    }
  }
});

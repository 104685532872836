import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import LayOut from '@/layouts/LayoutIndex.vue';
const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/login/PageIndex.vue'),
  },
  {
    path: '/',
    component: LayOut,
    name: 'page',
    meta: { title: '首页', icon: 'HomeFilled' },
  },
  {
    path: '/ums',
    component: LayOut,
    redirect: '/ums/role',
    name: 'ums',
    meta: { title: '权限', icon: 'HomeFilled' },
    children: [
      {
        path: 'admin',
        name: 'admin',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/ums/admin/PageIndex.vue'
          ),
        meta: { title: '用户列表', icon: 'ums-admin' },
      },
      {
        path: 'role',
        name: 'role',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/ums/role/PageIndex.vue'
          ),
        meta: { title: '角色列表', icon: 'HomeFilled' },
      },
      {
        path: 'allocResource',
        name: 'allocResource',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/ums/role/AllocResource.vue'
          ),
        meta: { title: '分配资源', icon: 'HomeFilled' },
      },
      {
        path: 'menu',
        name: 'menu',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/ums/menu/PageIndex.vue'
          ),
        meta: { title: '菜单列表', icon: 'ums-menu' },
      },
      {
        path: 'resource',
        name: 'resource',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/ums/resource/PageIndex.vue'
          ),
        meta: { title: '资源列表', icon: 'HomeFilled' },
      },
      {
        path: 'resourceCategory',
        name: 'resourceCategory',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/ums/resourceCategory/PageIndex.vue'
          ),
        meta: { title: '资源分类', icon: 'HomeFilled' },
      },
      {
        path: 'test',
        name: 'test',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/ums/test/PageIndex.vue'
          ),
        meta: { title: 'test', icon: 'HomeFilled' },
      },
    ],
  },
  {
    path: '/config',
    component: LayOut,
    redirect: '/config/currency',
    name: 'config',
    meta: { title: '币种列表', icon: 'HomeFilled' },
    children: [
      {
        path: 'currency',
        name: 'currency',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/config/currency/CurrencyIndex.vue'
          ),
        meta: { title: '币种列表', icon: 'ums-admin' },
      },
      {
        path: 'card',
        name: 'card',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/config/card/CardIndex.vue'
          ),
        meta: { title: '卡种列表', icon: 'ums-admin' },
      },
      {
        path: 'rate',
        name: 'rate',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/config/rate/RateIndex.vue'
          ),
        meta: { title: '汇率列表', icon: 'ums-admin' },
      },
    ],
  },
  {
    path: '/mms',
    component: LayOut,
    redirect: '/mms/member',
    name: 'mms',
    meta: { title: '会员管理' },
    children: [
      {
        path: 'member',
        name: 'member',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/mms/member/MemberIndex.vue'
          ),
        meta: { title: '会员列表', icon: 'ums-admin' },
      },
      {
        path: 'server',
        name: 'server',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/mms/server/ServerIndex.vue'
          ),
        meta: { title: '服务商列表', icon: 'ums-admin' },
      },
    ],
  },
  {
    path: '/fms',
    component: LayOut,
    redirect: '/fms/accountList',
    name: 'fms',
    meta: { title: '财务管理' },
    children: [
      {
        path: 'accountList',
        name: 'accountList',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/fms/accountList/PageIndex.vue'
          ),
        meta: { title: '账号列表', icon: 'ums-admin' },
      },
      {
        path: 'jour',
        name: 'jour',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/fms/jour/PageIndex.vue'
          ),
        meta: { title: '流水列表', icon: 'ums-admin' },
      },
    ],
  },
  {
    path: '/bms',
    component: LayOut,
    redirect: '/bms/bm',
    name: 'bms',
    meta: { title: '博客管理' },
    children: [
      {
        path: 'blogManage',
        name: 'blogManage',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/bms/bm/PageIndex.vue'
          ),
        meta: { title: '博客列表', icon: 'ums-admin' },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

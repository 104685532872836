import request from '@/utils/request';
//参与活动列表
export function getJoinList(data: any) {
  return request({
    url: '/member/join/activity/list',
    method: 'post',
    data: data,
  });
}
//活动总GMV数据
export function getGMVTotal(data: any) {
  return request({
    url: '/data/activity/activityTotalGMV',
    method: 'post',
    data: data,
  });
}
//某种活动的几期GMV数据
export function getOneGMV(data: any) {
  return request({
    url: '/data/activity/severalIssuesActivityGMV',
    method: 'post',
    data: data,
  });
}
//拼团客户数据
export function getMemberData(data: any) {
  return request({
    url: '/data/activity/member',
    method: 'post',
    data: data,
  });
}
//拼团商品数据
export function getProductData(data: any) {
  return request({
    url: '/data/activity/product',
    method: 'post',
    data: data,
  });
}
//拼团数据汇总
export function fetchAllData(data: any) {
  return request({
    url: '/data/activity/summary',
    method: 'post',
    data: data,
  });
}
//导出客户数据
export function exportMemberData(data: any) {
  return request({
    url: '/data/activity/member/export',
    method: 'post',
    data: data,
    responseType: 'blob',
  });
}
//导出商品数据
export function exportProductData(data: any) {
  return request({
    url: '/data/activity/product/export',
    method: 'post',
    data: data,
    responseType: 'blob',
  });
}
//导出拼团数据汇总
export function exportAllData(data: any) {
  return request({
    url: '/data/activity/summary/export',
    method: 'post',
    data: data,
    responseType: 'blob',
  });
}
//pay amount 50
export function fetchAmountTop(data: any) {
  return request({
    url: '/data/product/payAmount/top',
    method: 'post',
    data: data,
  });
}
//sale top 50
export function fetchSaleTop(data: any) {
  return request({
    url: '/data/product/sale/top',
    method: 'post',
    data: data,
  });
}
export function getMessageList(data: any) {
  return request({
    url: '/sys/sms/send/record/findListPage',
    method: 'post',
    data: data,
  });
}
//获取汇率列表
export function getRateList(data: any) {
  return request({
    url: '/sys/country/exchange/rate/findListPage',
    method: 'post',
    data: data,
  });
}
//更新汇率
export function updateRate(data: any) {
  return request({
    url: '/sys/country/exchange/rate/updateRate',
    method: 'post',
    data: data,
  });
}
//获取当前汇率
export function fetchRate() {
  return request({
    url: '/sys/country/exchange/rate/getEnableCountryExchangeRate',
    method: 'post',
  });
}
